<template>
    <div class="trip-preview-card-head" @click="open( trip.code )" v-if="translates && translates[langUrl]">
        <div class="trip-preview-card-head__carousel">
            <div :style="`background-image: url(${ imageSrc(trip.pictures[trip.mainPictureIndex]) })` " class="trip-preview-card-head__img"></div>
            <div v-if="buttons.indexOf('favorites') >= 0 && !inFavorites" class="trip-preview-card-head__carousel__button" @click.stop="favoriteAdd( trip._id )">
                <img class="trip-preview-card-head__carousel__button__icon" src="../../../assets/HeartPink.svg" />
                <div class="trip-preview-card-head__carousel__button__text">{{ translates[langUrl].button_favoriteAdd[lang] }}</div>
            </div>
            <div v-if="buttons.indexOf('favorites') >= 0 && inFavorites" class="trip-preview-card-head__carousel__button button-fill" @click.stop="favoriteDelete( trip._id )">
                <img class="trip-preview-card-head__carousel__button__icon" src="../../../assets/Heart.svg" />
                <div class="trip-preview-card-head__carousel__button__text button-fill">{{ translates[langUrl].button_favoriteDelete[lang] }}</div>
            </div>
            <!-- <div v-if="buttons.indexOf('favorites') < 0" class="trip-preview-card-head__carousel__button">
                <div class="trip-preview-card-head__carousel__button__text"> {{ status }} </div>
            </div> -->
            <div 
                v-if="trip.label"
                class="trip-preview-card-head__carousel__label first"
            > {{ formattedLabels }} </div>
            <div v-if="trip.soldOut" class="trip-preview-card-head__carousel__label second" >Sold out</div>
        </div>
        <div class="trip-preview-card-head__info">
            <h3 class="trip-preview-card-head__title">{{ trip.name }}</h3>
            <!-- <div class="trip-preview-card-head__stars">4,5
                <img src="../../../assets/Stars.jpg" />
            </div> -->
            <v-spacer />
            <div class="trip-preview-card-head__details-container">
                <div v-if="nearestArrival" class="trip-preview-card-head__details-container__details">
                    <span class="trip-preview-card-head__details-container__price">
                        <span v-html="priceFormated(nearestArrival.price, nearestArrival.currency)"></span>
                    </span> / {{ trip.programs.length }} {{ lastDigitToWord(trip.programs.length) }}
                </div>
                <div v-else class="trip-preview-card-head__details-container__empty" v-html="translates[langUrl].arrivals_noActive[lang]"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import moment from '@/plugins/moment';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated, lastDigitToWord } from '@/helpers'; 
    import { TRIP_STATUS, TRIP_STATUS_TITLE } from '@/vars';

    export default {
        name: 'TripPreviewCard',
        props: {
            trip: {
                type: Object,
                default: () => {},
            },
            buttons: {
                type: Array,
                default: () => []
            }
        },
        data: () => ({
            imageSrc,
            lastDigitToWord,
            TRIP_STATUS,
            priceFormated,
            langUrl: '/trips_previewCard'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('auth', ['user']),
            ...mapState('dictionaries', ['labels']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            nearestArrival() {
                if (this.trip.bloggerTrips && this.trip.bloggerTrips[0] && this.trip.bloggerTrips[0].start && this.trip.bloggerTrips[0].finish) {
                    return {
                        price: +this.trip.bloggerTrips[0].price + this.trip.bloggerTrips[0].bloggerAward,
                        currency: process.env.VUE_APP_DEFAULT_CURRENCY,
                        dates: `${ moment(this.trip.bloggerTrips[0].start).format('DD MMM') } - ${ moment(this.trip.bloggerTrips[0].finish).format('DD MMM') }`
                    };
                }
                if (this.trip.arrivals[0] && this.trip.arrivals[0].start && this.trip.arrivals[0].finish) {
                    return {
                        price: +this.trip.arrivals[0].price,
                        currency: this.trip.arrivals[0].currency,
                        dates: `${ moment(this.trip.arrivals[0].start).format('DD MMM') } - ${ moment(this.trip.arrivals[0].finish).format('DD MMM') }`
                    };
                } 
                return null;
            },
            formattedLabels() {
                return this.labels.filter(item => (item.code === this.trip.label))[0].title;
            },
            status () {
                return TRIP_STATUS_TITLE[this.trip.status];
            },
            mainPlacesArray () {
                return this.trip && this.trip.mainPlaces && this.trip.mainPlaces[0] ? this.trip.mainPlaces[0].split(',') : []
            },
            inFavorites() {
                if (this.user._id) {
                    return this.user.favoriteTrips.indexOf(this.trip._id) >= 0;
                }
                return false;
            }
        },
        methods: {
            async open(code) {
                await this.$router.push({ name: 'trip', params: { code } });
            },
            async edit(id) {
                if(this.user._id) {
                    await this.$router.push({ name: 'trip-edit', params: { id } });
                } else {
                    await this.$router.push({ name: 'user-authorization' });
                }
            },
            duration(arr) {
                let duration = arr.reduce((duration, currentValue) => {
                    return duration + currentValue.duration
                }, 0)
                return duration
            },
            arrivalsDates() {
                if (this.trip.arrivals[0]) {
                    return `${ moment(this.trip.arrivals[0].start).format('DD MMM') } - ${ moment(this.trip.arrivals[0].finish).format('DD MMM') }`;
                } 
                return '';
            },
            async favoriteAdd(id) {
                await this.$emit('favoriteAdd', { id });
            },
            async favoriteDelete(id) {
                await this.$emit('favoriteDelete', { id });
            },
            async setStatus(id, status) {
                await this.$emit('setStatus', { id, status });
            }
        }
    }


</script>

<style lang="scss">
.trip-preview-card-head {
	display: flex;
	flex-direction: column;
	width: calc(100% - 40px);
    // height: 98%;
	background: #FFFFFF;
	border-radius: 20px;
	margin-bottom: 20px;
    padding-bottom: 30px;
	overflow: hidden;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.02), 0px 4px 16px rgba(44, 47, 56, 0.12);
    cursor: pointer;
    @media all and (min-width:450px) {
        max-width: 375px;
    }
    @media all and (max-width: 768px) {
        &:first-of-type {
            margin-left: 20px;
        }
        &:last-of-type {
            margin-right: 20px;
        }
    }
    &__carousel {
        position: relative;
        width: 100%;
        height: 280px;
        

        &__button {
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 6.5px 8px;
            background: #FFFFFF;
            border-radius: 158px;
            cursor: pointer;
            z-index: 100;

            &__icon {
                width: 16px;
                height: 16px;
                // margin-right: 4px;
                // background-color: #FFFFFF;
            }

            &__text {
                font-size: 12px;
                line-height: 140%;
                letter-spacing: -0.02em;
                color: #E61E4D;
                flex: none;
                order: 1;
                flex-grow: 0;
                margin: 0px 4px;
                &.button-fill {
                    color: #FFFFFF;
                }
            }
            &.button-fill {
                background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
            }
        }

        &__label {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 5px 10px;
            position: absolute;
            left: 20px;
            top: 269px;
            background-color: #FFCD1D;
            border-radius: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            /* identical to box height, or 17px */
            letter-spacing: -0.015em;
            color: #FFFFFF;

            &.second {
                left: 120px;
                top: 269px;
                background: #48A7FF;
            }
        }
    }

    &__img {
        height: 100%;
        width: 100%;
        background-size: cover;
    }

    &__info {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0px;
        max-height: 130px;
    }

    &__title{
        display: flex;
        justify-content: flex-start;
        text-align: left;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.02em;
        margin-bottom: 4px;
    }

    &__stars {
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #707378;
    }

    &__comment{
        font-size: 12px;
        line-height: 15px;
        letter-spacing: -0.02em;
        color: #484848;
        opacity: 0.5;
        margin-bottom: 16px;
    }

    &__details-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 10px;

        &__details {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #484848;
        }

        &__price {
            font-weight: 600;
        }

        &__dates {
            display: flex;
            flex-direction: row;
            // align-items: center;

            &__icon {
                width: 20px;
                height: 20px;
                margin-right: 4px;
            }

            &__text {
                font-size: 14px;
                line-height: 140%;
                text-align: right;
                letter-spacing: -0.02em;
                color: #707378;
            }
        }

        &__empty {
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            text-align: left;
            color: #707378;
        }
    }

    &__description {
            font-size: 14px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #44414D;
            padding: 20px 20px 15px;

        &__container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 10px;
        }

        &__span {
            color: #DA0A63;
        }
        
    }

    &__button-grey {
        padding: 10px 20px 10px 10px;
        margin-bottom: 18px;
        width: calc(100% - 48px);
        height: 44px;
        background: #F6F7F9;;;
        border-radius: 10px;
        align-self: center;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        letter-spacing: -0.02em;
        color: #484848;
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        margin: 0 24px 10px;
        justify-content: space-between;
    }

    &__button {
        width: 139px;
        height: 48px;
        border: 1px solid #DA0A63 !important;
        box-sizing: border-box;
        border-radius: 10px;
        font-size: 14px;
        line-height: 140%;
        padding: 10px;
        margin-bottom: 10px;
        letter-spacing: -0.02em;
        background: #FFFFFF;
        color: #DA0A63;
        cursor: pointer;
        &__icon {
            width: 15px;
            height: 15px;
            margin-left: 5px;
        }
    }

    &__button-fill {
        background: linear-gradient(270deg, #D80765 0%, #E61E4D 100%);
        color: #FFFFFF;
    }
    &__button-full {
        width: calc(100% - 48px);
        align-self: center;
        margin-bottom: 18px;
    }
}
</style>