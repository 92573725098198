<template>
    <div class="form-question" v-if="translates && translates[langUrl]">
        <div v-if="!formSended">
            <BaseTextField
                v-model="feedback.name"
                class="form"
                :placeholder="translates[langUrl].placeholder_name[lang]"
            />
            <BasePhoneNumberInput
                v-model="feedback.phone"
                @update="number($event)"
            />
            <BaseTextField
                v-model="feedback.email"
                class="form"
                :placeholder="translates[langUrl].placeholder_email[lang]"
            />
            <BaseTextArea
                v-model="feedback.comment"
                class="form"
                :placeholder="translates[langUrl].placeholder_comment[lang]"
            />
            <BaseButton
                :disabled="!feedback.name || !feedback.phone || !feedback.personalDataAgree"
                class="button-fill"
                style="width:100%"
                @click="send">{{ translates[langUrl].button_send[lang] }}</BaseButton>
            <div class="form-question__personal-info">
                <BaseCheckbox 
                    v-model="feedback.personalDataAgree"
                />
                <div class="form-question__personal-info__text">
                    {{ translates[langUrl].personalInfo[lang] }} 
                    <a :href="privacy" target="_blank">{{ translates[langUrl].personalInfo_part2[lang] }}</a>
                </div>
            </div>
        </div>
        <div v-if="formSended" class="form-question__sended">
            <div class="form-question__sended__text">{{ translates[langUrl].formSended[lang] }}</div>
            <BaseButton
                class="button-fill"
                style="width:100%"
                @click="formReverse"
            >{{ translates[langUrl].button_oneMore[lang] }}</BaseButton>
        </div>
    </div>
</template>
<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import BaseTextField from './BaseTextField.vue';
    import BaseTextArea from './BaseTextArea.vue';
    import BaseButton from './BaseButton.vue';
    import BaseCheckbox from './BaseCheckbox.vue';
    import BasePhoneNumberInput from '../../components/common/BasePhoneInput.vue';

    export default {
        name: 'FormQuestions',
        components: {
            BaseTextField,
            BaseTextArea,
            BaseButton,
            BaseCheckbox,
            BasePhoneNumberInput
        },
        data: () => ({
            feedback: {
                name: '',
                phone: '',
                email: '',
                comment: '',
                link: '',
                personalDataAgree: true,
            },
            phoneWithCode: '',
            formSended: false,
            langUrl: 'FormQuestions'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            privacy() {
                return `${ process.env.VUE_APP_CURRENT_URL }/privacy-policy`;
            },
        },
        methods: {
            async send() {
                this.feedback.phone = this.phoneWithCode;
                this.feedback.link = document.location.href;
                await store.commit('feedbacks/SET_ENTITY', this.feedback);
                await store.dispatch('feedbacks/save');
                await store.commit('feedbacks/CLEAR_ENTITY');
                await this.clear()
                await this.formReverse()
            },
            async formReverse() {
                this.formSended = !this.formSended
            },
            async clear () {
                this.feedback = {
                    name: '',
                    phone: '',
                    email: '',
                    comment: '',
                    link: '',
                    personalDataAgree: true
                }
            },
            number(event) {
                this.phoneWithCode = event.formattedNumber
            }
        }
    }

</script>
<style lang="scss">
.form-question {
    font-family: Inter;
    font-style: normal;
    width: 450px;
    min-width: 350px;
    height: 569px;
    padding: 40px 30px 24px;
    background: #fff;
    box-shadow: 0px 4px 3px rgba(44, 47, 56, 0.01), 0px 4px 20px rgba(44, 47, 56, 0.08);
    border-radius: 12px;
    @media all and (max-width: 768px) {
        width: 335px;
        height: 561px;
        padding: 20px 20px 25px;
    }
    &__personal-info {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;
        &__text {
            font-weight: normal;
            font-size: 14px;
            line-height: 166%;
            color: #A9AEB2;
            text-align: left;
            @media all and (max-width: 768px) {
                font-weight: 300;
                font-size: 12px;
            }
            span {
                font-weight: 500;
                color: black;
            }
        }
    }
    &__sended {
        margin-top: 50%;
        &__text {

            margin-bottom: 15px;
        }
    }
}
</style>