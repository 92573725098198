<template>
    <div class="review">
        <div class="review__user">
            <div v-if="review.user && review.user.avatar" class="review__user__avatar" :style="`background-image: url(${review.user ? imageSrc(review.user.avatar) : ''})`"></div>
            <div v-if="review.externalUser && review.externalUser.avatar" class="review__user__avatar" :style="`background-image: url(${review.externalUser ? imageSrc(review.externalUser.avatar) : ''})`"></div>
            <div v-else class="review__user__avatar"></div>
            <div style="display: flex; flex-direction: column">
                <div style="display: flex">
                    <div class="review__user__name">
                        <template v-if="review.user">
                            {{ review.user.firstname }} {{ review.user.lastname ? review.user.lastname[0] : '' }}.
                        </template>
                        <template v-else>
                            {{ review.externalUser.firstname }} {{ review.externalUser.lastname ? review.externalUser.lastname[0] : '' }}.
                        </template>
                    </div>
                    <div class="review__time">{{ review.date | moment('L') }}</div>
                </div>
                <div style="display: flex; align-items: center; margin-top: 5px;">
                    <div class="review__user__raiting">{{ review.rating.overall }}</div>
                    <v-rating
                        :value="review.rating.overall"
                        background-color="grey"
                        color="warning"
                        half-increments
                        hover
                        length="5"
                        size="15"
                        dense
                        readonly
                        style="margin-left: 5px"
                    />
                </div>
            </div>
        </div>
        <div v-if="review.trip" class="review__trip">{{ review.trip.name }}</div>
        <div class="review__text">{{ review.text }}</div>
        <!-- <a class="review__more">Подробнее</a> -->
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import { imageSrc } from '@/helpers';

    export default {
        name: 'Review',
        props: {
            review: {
                type: Object,
                default: () => {}
            }
        },
        data: () => ({
            imageSrc,
        }),
        computed: {
            ...mapState('reviews', ['entities']),
        },
        methods: {
            // calculateRating(name) {
            //     let arrRating = this.entities.map(function(entity){
            //         return entity.rating[name]
            //     })
            // },
            
        }
    }
</script>


<style lang="scss">
.review {
    padding: 20px;
    box-shadow: 0px 4px 22px rgba(44, 47, 56, 0.04);
    border-radius: 12px;
    max-width: 420px;
    margin-bottom: 20px;
    // height: 300px;
    @media all and(max-width: 768px) {
        width: 100%;
        padding: 20px;
        height: auto;
    }
    &__time{
        margin-left: 12px;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #93A3B4;
    }
    &__user {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
        @media all and(max-width: 768px) {
            margin-bottom: 15px;
        }
        &__avatar{
            width: 50px;
            height: 50px;
            border-radius: 12px 12px 0px 0px;
            border-radius: 50%;
            background-color: #F6F7F9;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            margin-right: 20px;
        }
        &__name {
            font-weight: 500;
            font-size: 18px;
            line-height: 22px;
            letter-spacing: -0.02em;
            color: #3A4256;

        }
        &__raiting {
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #707378;
            // margin-top: 5px;
        }
    }
    &__trip {
        display: flex;
        // max-width: 200px;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #93A3B4;
        margin-bottom: 10px;
        @media all and(max-width: 768px) {
            max-width: 100%;
            margin-bottom: 20px;
        }
    }
    &__text{
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #3A4256;
        // height: 110px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
    &__more {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #E61E4D;
        margin: 10px auto 10px 0;
    }
}
</style>