<template>
    <div class="main-page__organizer" v-if="translates && translates[langUrl]">
        <div class="main-page__organizer__main-container">
            <div v-if="user" class="main-page__organizer__photo" :style="`background-image: url(${ imageSrc(user.avatar) })`"></div>
            <div class="main-page__organizer__text-container">
                <div class="main-page__organizer__name"> 
                    <template v-if="user.firstname">{{ user.firstname }} </template>
                    <template v-if="user.lastname">{{ user.lastname }}</template>.</div>
                <!-- <div class="main-page__organizer__from">{{ translates[langUrl].registered_since[lang] }} 
                    <template v-if="user">{{ user.createdAt | moment('YYYY') }}</template>
                </div> -->
                <!-- <div class="organizer__confirmation">
                    <img src="../../assets/ShieldTick.svg" style="margin-right: 2px" />
                    {{ translates[langUrl].confirmed[lang] }}
                </div> -->
                <!-- <div class="main-page__organizer__confirmation">
                    4,5
                    <img src="../../assets/StarAlone.svg" style="margin: 0 4px" />
                    (677)
                </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { imageSrc } from '@/helpers';
    import { mapState } from 'vuex';

    export default {
        name: 'Organizer',
        props: {
            user: { 
                type: Object, 
                default: () => {}
            }
        },
        data: () => ({
            imageSrc,
            hide: true,
            langUrl: 'organizer'
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
        }
    }
</script>

<style lang="scss">
.main-page__organizer {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    @media all and (max-width: 768px) {
        margin-right: 20px;
        &:first-of-type {
            margin-left: 20px;
        }
    }
    &__main-container {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        width: 210px;
    }
    &__photo {
        width: 100%;
        height: 210px;
        border-radius: 10px;
        background-position: center;
        background-size: cover !important;
    }
    &__text-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
    }
    &__name {
        font-weight: 600;
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.02em;
        color: #273155;
        margin-top: 12px;
        margin-bottom: 4px;
    }
    &__from {
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.02em;
        color: #93A3B4;
    }
    &__confirmation {
        display: flex;
        flex-direction: row;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;
        color: #3A4256;

        margin-top: 12px;
    }
}
</style>